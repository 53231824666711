.navBar-root {
  height: 100vh;
  width: 100%;
  position: relative;
  border-radius: 0 !important;

  .MuiListItemIcon-root {
    min-width: 0 !important;
  }
}

.navBar-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navBar-selected {
  background-color: rgba(111, 44, 145, 0.4) !important;
  color: #6f2c91 !important;
  font-weight: 700 !important;
  border-right: 4px solid #6f2c91 !important;
}

.navBar-selected-dark {
  background-color: rgba(0, 178, 175, 0.4) !important;
  color: #00b2af !important;
  font-weight: 700 !important;
  border-right: 4px solid rgb(0, 178, 175) !important;
}

.navBar-icon-selected {
  color: #6f2c91 !important;
  font-weight: 700 !important;
}

.navBar-icon-selected-dark {
  color: #00b2af !important;
  font-weight: 700 !important;
}

.navBar-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 88px;
  left: 0;
  right: 0;
  bottom: 60px;
  overflow-y: scroll;
  // width: 256px;

  .MuiAccordion-root {
    border-radius: 0 !important;
    box-shadow: none !important;
  }
  .MuiButtonBase-root {
    width: 100% !important;
  }
}

.navBar-tab {
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.navBar-tab:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.navBar-content::-webkit-scrollbar {
  display: none;
}

.navBar-footer {
  position: absolute;
  height: 50px;

  left: 0;
  right: 0;
  bottom: 0;
}

.navBar-divider {
  margin-top: 2px !important;
  color: #ada5b1;
}
