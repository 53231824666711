.root {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.root_container {
  display: flex;
}

.root_content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.root_main {
  display: flex;
  flex-direction: column;
  padding: 8px;
  height: 100vh;
  overflow-y: scroll;
}
.root_main::-webkit-scrollbar {
  display: none;
}

.root_navBar {
  background: #ffffff;

  flex: 0 0 auto;
}

.MuiButtonBase-root {
  display: flex;
  min-height: 48px !important;
}
