.top-card {
  border-radius: 8px;
  padding-top: 2rem 1rem 1rem 1rem;

  .icon-total {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;

    .count {
      font-size: 2.5rem;
      font-weight: bold;
      color: #fff;
      text-align: right;
    }

    .material-icons {
      font-size: 2.5rem;
      font-weight: bold;
      color: #fff;
    }
  }

  .title {
    color: #fff;
  }

  .default-color {
    background-color: #5f147d;
  }

  .custom-color-1 {
    background-color: #519ca6;
  }

  .custom-color-2 {
    background-color: #6a4e8e;
  }

  .custom-color-3 {
    background-color: #594e8e;
  }

  .custom-color-4 {
    background-color: #567199;
  }
}
