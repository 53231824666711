@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  min-height: 100%;
}

.iconButton-hover-bg:hover {
  background-color: transparent !important;
}

.MuiSwitch-thumb {
  margin-bottom: 12px !important;
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}

.MuiRadio-root:hover {
  background-color: transparent !important;
}
