.uploader {
  display: flex;
  color: gray;

  img {
    margin-bottom: 16px;
  }

  .drop-progress {
    width: 295px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .drop-area {
    border: 1px dashed #ddd;
    border-radius: 4px;
    cursor: pointer;
    padding: 32px;
    text-align: center;
  }

  .send-button {
    background-color: #6f2c91 !important;
    color: white;
    text-transform: capitalize;
  }

  .send-button:hover {
    color: #6f2c91;
    background-color: white !important;
  }
}
