.feature-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: 1rem;
    box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}

.feature-card-body {
    padding: 1rem !important;
}

.border-radius-md {
    border-radius: 0.5rem;
}
.icon-shape {
    width: 48px;
    height: 48px;
    background-position: 50%;
    border-radius: 0.75rem;
    position: relative;
}
.bg-gradient-primary {
    background-image: linear-gradient(310deg,var(--light-primary-color),var(--light-secondary-color));
}
.text-center {
    text-align: center!important;
}
.shadow {
    box-shadow: 0 .25rem .375rem -.0625rem hsla(0,0%,8%,.12),0 .125rem .25rem -.0625rem hsla(0,0%,8%,.07)!important;
}

.feature-card-icon {
    position: absolute;
    left: calc(38% - 8px);
    top: calc(35% - 8px);
    font-size: 2rem !important;
    color: #fff;
}

.complement {
    margin-left: 3rem;
    color: green;
}