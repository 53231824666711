.notification-container {
  min-width: 320px;
  max-width: 380px;
}

.notification-empty {
  padding: 20px;
  text-align: center;
  color: var(--light-primary-color);
  font-size: 0.75rem;
}

.notification-header {
  background-color: var(--light-primary-color);
  height: 60px;

  display: flex;
  align-items: center;

  color: var(--dark-primary-color);
}

.notification-header span {
  padding: 8px;
  font-weight: 600;

  font-size: 0.875rem;
}

.notification-item {
  cursor: pointer;
}
.notification-item:hover {
  background-color: rgba(109, 109, 109, 0.2);
}

.notification-item-text {
  font-size: 0.75rem;

  display: flex;
  align-items: center;

  color: var(--dark-secondary-color);
  font-weight: 300;
}

.notification-item-content {
  font-size: 0.875rem;
  padding: 8px 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-item-time {
  font-size: 0.725rem;
  padding-left: 12px;
}
