.page-title-container {
  display: flex;
  flex-direction: column;
  height: 82px;
}

.page-title {
  font-size: 1.5em !important;
  text-transform: uppercase;
  font-weight: 700;
}

.page-subtitle {
  font-size: 0.875rem !important;
}

.page-title-divider {
  padding-top: 17px;
}
